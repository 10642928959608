.page-link {
  width: 100%;
  margin-top: 15%;
  position: absolute;
}

.page-link-parent {
  width: 100%;
  display: flex;
}

.page-link-child {
  margin-left: 10%;
}

.page-link-button {
  width: 130%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-link-button:hover {
  -webkit-transform: rotate(20deg);
  -moz-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}

#page-link-1 {
  margin-top: 0%;
  opacity: 0;
  pointer-events: none;
  /* アニメーション設定 */
  animation-name: fade-in;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  99% {
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: auto;
  }
}

#page-link-icon-1 {
  width: 80%;
}

#page-link-icon-2 {
  width: 100%;
}

#page-link-icon-3 {
  width: 60%;
}

#page-link-icon-4 {
  width: 80%;
}

#page-link-icon-5 {
  width: 100%;
}

#page-link-icon-6 {
  width: 70%;
}

/* スマホ */
@media screen and (max-width: 768px){
  .page-link {
    width: 100%;
    margin-top: 60%;
    position: absolute;
  }

  .page-link-parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
  }

  .page-link-child {
    margin-left: 10%;
    margin-top: 5%;
  }

  .page-link-button {
    width: 70%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .page-link-button:hover {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  #page-link-1 {
    margin-top: 0%;
    opacity: 0;
    pointer-events: none;
    /* アニメーション設定 */
    animation-name: fade-in;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      pointer-events: none;
    }
    99% {
      pointer-events: none;
    }
    100% {
      opacity: 1;
      pointer-events: auto;
    }
  }

  #page-link-icon-1 {
    width: 100%;
  }

  #page-link-icon-2 {
    width: 100%;
  }

  #page-link-icon-3 {
    width: 100%;
  }

  #page-link-icon-4 {
    width: 100%;
  }

  #page-link-icon-5 {
    width: 100%;
  }

  #page-link-icon-6 {
    width: 100%;
  }
}
