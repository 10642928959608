@import url('https://fonts.googleapis.com/css2?family=Sawarabi+Mincho&family=Zen+Kurenaido&display=swap');

html{
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Times New Roman', 'YuMincho', 'Hiragino Mincho ProN',
    'Yu Mincho', 'MS PMincho', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;

}

.cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

p {
  margin-top: 5px;
}

button:focus {
  outline: 0;
}
