.page-novel {
  align-items: center;
  min-height: 50vh;
}

p.page-novel-text {
  font-family: 'Sawarabi Mincho', serif;
  margin-bottom: 20px;
  font-size: normal;
  font-weight: normal;
  line-height: 36px;
}

p.page-novel-text-italic {
  font-family: 'Zen Kurenaido', sans-serif;
  margin-bottom: 20px;
  font-size: normal;
  font-weight: normal;
  line-height: 36px;
}

h2.page-novel-hader {
  margin-bottom: 20px;
  font-size: x-large;
  font-weight: 800;
  line-height: 36px;
}

.postscript-sns-link{
  margin-left: 10px;
}
