@import url(https://fonts.googleapis.com/css2?family=Sawarabi+Mincho&family=Zen+Kurenaido&display=swap);
html{
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Times New Roman', 'YuMincho', 'Hiragino Mincho ProN',
    'Yu Mincho', 'MS PMincho', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;

}

.cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

p {
  margin-top: 5px;
}

button:focus {
  outline: 0;
}

/* トップページ */
#toppage {
  height: 100%;
  width: 100%;
  position: relative;
}

/* タイトルロゴ */
.cover-toppage-title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 40%;
  width: 40%;
}

.cover-toppage-title img {
  margin-top: 5px;
  text-align: center;
}

/* トップページの説明文 */
.cover-toppage-title .description {
  margin-top: 50px;
  text-align: center;
}

.cover-toppage-title .description p {
  color: white;
  font-size: medium;
  font-weight: 600;
}

.cover-toppage-title .description .description-small {
  margin-top: 8px;
  font-size: x-small;
}

.cover-toppage-title .description .message {
  margin-top: 10px;
  font-size: large;
  color: #693cea;
}

/* スマホ */
@media screen and (max-width: 768px){
  .cover-toppage-title {
    position: absolute;
    top: 40%;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 70%;
    width: 70%;
  }

  /* トップページの説明文 */
  .cover-toppage-title .description {
    margin-top: 20%;
    text-align: center;
  }

  .cover-toppage-title .description p {
    color: white;
    font-size: x-small;
    font-weight: 600;
  }

  .cover-toppage-title .description .description-small {
    margin-top: 8px;
    font-size: xx-small;
  }

  .cover-toppage-title .description .message {
    margin-top: 10px;
    font-size: medium;
    color: #693cea;
  }
}

/* 小説ページ */
.cover-novel-page {
  height: 100%;
  width: 100%;
  position: relative;
}

.cover-novel-page-title-logo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 10px 15px;
}

/* スマホ */
@media screen and (max-width: 768px){
  .cover-novel-page-title-logo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 32%;
    margin: auto;
    width: 200%;
  }
}

/* 背景画像 */
#cover-0 {
  background-image: url(/static/media/1.9d8e65f6.jpg);
}

#cover-1 {
  background-image: url(/static/media/1.9d8e65f6.jpg);
}

#cover-2 {
  background-image: url(/static/media/2.c928d4c2.jpg);
}

#cover-3 {
  background-image: url(/static/media/3.7fbd2e1b.jpg);
}

#cover-4 {
  background-image: url(/static/media/4.ce4cd72b.jpg);
}

#cover-5 {
  background-image: url(/static/media/5.1be7ad17.jpg);
}

#cover-6 {
  background-image: url(/static/media/6.b5402ae6.jpg);
}

#cover-7 {
  background-color: black;
  color: white;
}

#cover-8 {
  background-color: white;
}

/* 奥付ページ */
#thanks-page {
  height: 100%;
  width: 100%;
  position: relative;
}

.cover-thanks-page-title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 40%;
  width: 40%;
}

.cover-thanks-button {
  text-align: center;
  margin-top: 10%;
}

h2.cover-thanks-header {
  font-size: xx-large;
  text-align: center;
  color: #dd2a7b;
  margin-top: 20%;
}

p.cover-thanks-description {
  color: white;
  text-align: center;
}

p.cover-thanks-page-title-reload-button-description {
  color: #8768e1;
  text-align: center;
  font-weight: bold;
}

.cover-thanks-page-web-clap {
  height: 20%;
  width: 20%;
  position: absolute;
  bottom: 20%;
  right: 5%;
}

button.cover-thanks-page-title-webclap-button{
  transition: all 0.3s ease;
}

button.cover-thanks-page-title-webclap-button:hover{
  transform: rotate(20deg);
}

.cover-thanks-page-postscript {
  position: absolute;
  top: 30%;
  left: 10%;
}

button.cover-thanks-page-postscript-button{
  transition: all 0.3s ease;
}

button.cover-thanks-page-postscript-button:hover{
  transform: rotate(20deg);
}

@media screen and (max-width: 768px){
  .cover-thanks-page-title{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 60%;
    width: 70%;
  }

  p.cover-thanks-description {
    color: white;
    text-align: center;
    font-size: small;
  }

  .cover-thanks-page-web-clap {
    height: 40%;
    width: 40%;
    position: absolute;
    top: 55%;
    left: 7%;
  }

  .cover-thanks-page-postscript {
    height: 40%;
    width: 40%;
    position: absolute;
    top: 55%;
    left: 60%;
  }

}

.page-novel {
  align-items: center;
  min-height: 50vh;
}

p.page-novel-text {
  font-family: 'Sawarabi Mincho', serif;
  margin-bottom: 20px;
  font-size: normal;
  font-weight: normal;
  line-height: 36px;
}

p.page-novel-text-italic {
  font-family: 'Zen Kurenaido', sans-serif;
  margin-bottom: 20px;
  font-size: normal;
  font-weight: normal;
  line-height: 36px;
}

h2.page-novel-hader {
  margin-bottom: 20px;
  font-size: x-large;
  font-weight: 800;
  line-height: 36px;
}

.postscript-sns-link{
  margin-left: 10px;
}

.novel-modal {
  background-image: url(/static/media/paper.6016d971.png);
  background-position: center;
  border-radius: 0.5em;
  background-repeat: no-repeat;
  background-size: cover;
  color: #3c4043;
  display: block;
  left: 50%;
  max-width: 70%;
  min-width: 50em;
  padding: 2em;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: transparent;
  min-height: 70%;
  max-height: calc(100vh - 30vh);
  overflow-y: auto;
}

.novel-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.novel-modal-close {
  position: absolute;
  display: block;
  left: 85%;
  padding-bottom: 15px;
}

.novel-modal-close-button {
  font-family: 'Playball', cursive;
  font-size: xx-large;
  transition: all 0.3s ease;
}

.novel-modal-close-button:hover {
  transform: rotate(10deg);
}

/* スマホ */
@media screen and (max-width: 768px){
  .novel-modal {
    background-image: url(/static/media/paper.6016d971.png);
    background-position: center;
    border-radius: 0.5em;
    background-repeat: no-repeat;
    background-size: cover;
    color: #3c4043;
    font-size:small;
    display: block;
    left: 50%;
    max-width: 100%;
    min-width: 25em;
    padding: 1em;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    outline: transparent;
    min-height: 90%;
    max-height: calc(100vh - 10vh);
    overflow-y: auto;
  }

  .novel-modal-close {
    position: absolute;
    display: block;
    left: 70%;
    padding-bottom: 15px;
  }
}

.page-link {
  width: 100%;
  margin-top: 15%;
  position: absolute;
}

.page-link-parent {
  width: 100%;
  display: flex;
}

.page-link-child {
  margin-left: 10%;
}

.page-link-button {
  width: 130%;
  transition: all 0.3s ease;
}

.page-link-button:hover {
  transform: rotate(20deg);
}

#page-link-1 {
  margin-top: 0%;
  opacity: 0;
  pointer-events: none;
  /* アニメーション設定 */
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  99% {
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: auto;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  99% {
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: auto;
  }
}

#page-link-icon-1 {
  width: 80%;
}

#page-link-icon-2 {
  width: 100%;
}

#page-link-icon-3 {
  width: 60%;
}

#page-link-icon-4 {
  width: 80%;
}

#page-link-icon-5 {
  width: 100%;
}

#page-link-icon-6 {
  width: 70%;
}

/* スマホ */
@media screen and (max-width: 768px){
  .page-link {
    width: 100%;
    margin-top: 60%;
    position: absolute;
  }

  .page-link-parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
  }

  .page-link-child {
    margin-left: 10%;
    margin-top: 5%;
  }

  .page-link-button {
    width: 70%;
    transition: all 0.3s ease;
  }

  .page-link-button:hover {
    transform: rotate(20deg);
  }

  #page-link-1 {
    margin-top: 0%;
    opacity: 0;
    pointer-events: none;
    /* アニメーション設定 */
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
      pointer-events: none;
    }
    99% {
      pointer-events: none;
    }
    100% {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      pointer-events: none;
    }
    99% {
      pointer-events: none;
    }
    100% {
      opacity: 1;
      pointer-events: auto;
    }
  }

  #page-link-icon-1 {
    width: 100%;
  }

  #page-link-icon-2 {
    width: 100%;
  }

  #page-link-icon-3 {
    width: 100%;
  }

  #page-link-icon-4 {
    width: 100%;
  }

  #page-link-icon-5 {
    width: 100%;
  }

  #page-link-icon-6 {
    width: 100%;
  }
}

