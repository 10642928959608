/* トップページ */
#toppage {
  height: 100%;
  width: 100%;
  position: relative;
}

/* タイトルロゴ */
.cover-toppage-title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 40%;
  width: 40%;
}

.cover-toppage-title img {
  margin-top: 5px;
  text-align: center;
}

/* トップページの説明文 */
.cover-toppage-title .description {
  margin-top: 50px;
  text-align: center;
}

.cover-toppage-title .description p {
  color: white;
  font-size: medium;
  font-weight: 600;
}

.cover-toppage-title .description .description-small {
  margin-top: 8px;
  font-size: x-small;
}

.cover-toppage-title .description .message {
  margin-top: 10px;
  font-size: large;
  color: #693cea;
}

/* スマホ */
@media screen and (max-width: 768px){
  .cover-toppage-title {
    position: absolute;
    top: 40%;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 70%;
    width: 70%;
  }

  /* トップページの説明文 */
  .cover-toppage-title .description {
    margin-top: 20%;
    text-align: center;
  }

  .cover-toppage-title .description p {
    color: white;
    font-size: x-small;
    font-weight: 600;
  }

  .cover-toppage-title .description .description-small {
    margin-top: 8px;
    font-size: xx-small;
  }

  .cover-toppage-title .description .message {
    margin-top: 10px;
    font-size: medium;
    color: #693cea;
  }
}

/* 小説ページ */
.cover-novel-page {
  height: 100%;
  width: 100%;
  position: relative;
}

.cover-novel-page-title-logo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 10px 15px;
}

/* スマホ */
@media screen and (max-width: 768px){
  .cover-novel-page-title-logo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 32%;
    margin: auto;
    width: 200%;
  }
}

/* 背景画像 */
#cover-0 {
  background-image: url(img/1.jpg);
}

#cover-1 {
  background-image: url(img/1.jpg);
}

#cover-2 {
  background-image: url(img/2.jpg);
}

#cover-3 {
  background-image: url(img/3.jpg);
}

#cover-4 {
  background-image: url(img/4.jpg);
}

#cover-5 {
  background-image: url(img/5.jpg);
}

#cover-6 {
  background-image: url(img/6.jpg);
}

#cover-7 {
  background-color: black;
  color: white;
}

#cover-8 {
  background-color: white;
}

/* 奥付ページ */
#thanks-page {
  height: 100%;
  width: 100%;
  position: relative;
}

.cover-thanks-page-title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 40%;
  width: 40%;
}

.cover-thanks-button {
  text-align: center;
  margin-top: 10%;
}

h2.cover-thanks-header {
  font-size: xx-large;
  text-align: center;
  color: #dd2a7b;
  margin-top: 20%;
}

p.cover-thanks-description {
  color: white;
  text-align: center;
}

p.cover-thanks-page-title-reload-button-description {
  color: #8768e1;
  text-align: center;
  font-weight: bold;
}

.cover-thanks-page-web-clap {
  height: 20%;
  width: 20%;
  position: absolute;
  bottom: 20%;
  right: 5%;
}

button.cover-thanks-page-title-webclap-button{
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

button.cover-thanks-page-title-webclap-button:hover{
  -webkit-transform: rotate(20deg);
  -moz-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}

.cover-thanks-page-postscript {
  position: absolute;
  top: 30%;
  left: 10%;
}

button.cover-thanks-page-postscript-button{
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

button.cover-thanks-page-postscript-button:hover{
  -webkit-transform: rotate(20deg);
  -moz-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}

@media screen and (max-width: 768px){
  .cover-thanks-page-title{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 60%;
    width: 70%;
  }

  p.cover-thanks-description {
    color: white;
    text-align: center;
    font-size: small;
  }

  .cover-thanks-page-web-clap {
    height: 40%;
    width: 40%;
    position: absolute;
    top: 55%;
    left: 7%;
  }

  .cover-thanks-page-postscript {
    height: 40%;
    width: 40%;
    position: absolute;
    top: 55%;
    left: 60%;
  }

}
