.novel-modal {
  background-image: url(../img/paper.png);
  background-position: center;
  border-radius: 0.5em;
  background-repeat: no-repeat;
  background-size: cover;
  color: #3c4043;
  display: block;
  left: 50%;
  max-width: 70%;
  min-width: 50em;
  padding: 2em;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: transparent;
  min-height: 70%;
  max-height: calc(100vh - 30vh);
  overflow-y: auto;
}

.novel-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.novel-modal-close {
  position: absolute;
  display: block;
  left: 85%;
  padding-bottom: 15px;
}

.novel-modal-close-button {
  font-family: 'Playball', cursive;
  font-size: xx-large;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.novel-modal-close-button:hover {
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}

/* スマホ */
@media screen and (max-width: 768px){
  .novel-modal {
    background-image: url(../img/paper.png);
    background-position: center;
    border-radius: 0.5em;
    background-repeat: no-repeat;
    background-size: cover;
    color: #3c4043;
    font-size:small;
    display: block;
    left: 50%;
    max-width: 100%;
    min-width: 25em;
    padding: 1em;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    outline: transparent;
    min-height: 90%;
    max-height: calc(100vh - 10vh);
    overflow-y: auto;
  }

  .novel-modal-close {
    position: absolute;
    display: block;
    left: 70%;
    padding-bottom: 15px;
  }
}
